import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';

const styles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '1rem',
        backgroundColor: '#f0f4f8',
    },
    card: {
        textAlign: 'center' as CSSProperties['textAlign'],
        maxWidth: '500px',
        width: '100%',
        backgroundColor: '#ffffff',
        padding: '2rem',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    },
    title: {
        fontSize: '1.8rem',
        color: '#4CAF50',
        marginBottom: '1rem',
    },
    message: {
        fontSize: '1rem',
        color: '#333333',
        lineHeight: '1.5',
        marginBottom: '1.5rem',
    },
    button: {
        backgroundColor: '#4CAF50',
        color: '#ffffff',
        padding: '0.75rem 1.5rem',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
};

const CorretorCriado = () => {
    const history = useHistory();

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.title}>Corretor Criado com Sucesso! 🎉</h2>
                <p style={styles.message}>
                    O corretor foi cadastrado com sucesso! Nossa equipe de tecnologia configurará o perfil completo dele em breve no Construtor de Venda (CV).
                </p>
            </div>
        </div>
    );
};

export default CorretorCriado;