import styled from 'styled-components';
import cores from '../../../styles/cores';

export const FormContainer = styled.div`
  max-width: 50%;
  margin: 0 auto;
  padding: 25px;
  border-radius: 15px;
  box-shadow: ${cores.shadow_1};
  overflow: hidden;
  background-color: '#fffff'; 
  border: 1px solid ${cores.lightgray || '#e0e0e0'};
  @media (max-width: 768px) {
    padding: 20px;
    box-shadow: none;
    max-width: 100%;
    display: flex;
    flex-flow: column;
    form {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
  }
  h3 {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: ${cores.purple_2};
  }
`;

export const FormTitle = styled.h1`
  text-align: center;
  font-size: 2.2rem;
  color: ${cores.black};
  margin-bottom: 2rem;
  font-family: 'Arial', sans-serif;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const Section = styled.div`
  margin-bottom: 2.5rem; 
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: #4f4f4f;
  margin-bottom: 1.5rem;
  font-weight: 600;
  text-align: left; 
  @media (max-width: 768px) {
    font-size: 1.4rem;
    text-align: center; 
  }
`;

export const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const InputWrapper = styled.div`
  flex: 1;
  min-width: 280px;
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const ContainerSubmitButton = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled.button`
  width: 50%; 
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background: ${cores.purple_1};
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin: 0 0 0.5rem 0;
  &:hover {
    background: ${cores.purple_3}; 
    transform: translateY(-3px); 
  }
  &:active {
    transform: translateY(0); 
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CancelButton = styled.button`
  width: 50%; 
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background: ${cores.red};
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin: 0 0 0.5rem 0;
  &:hover {
    background-color: #ff4747; 
    transform: translateY(-3px); 
  }
  &:active {
    transform: translateY(0); 
  }
  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  color: ${cores.red};
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
`;