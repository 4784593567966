import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Snackbars from '../../../components/Snackbars';
import UsuariosService from '../../../services/UserService';
import {
    FormContainer,
    FormTitle,
    Section,
    SectionTitle,
    FieldsRow,
    InputWrapper,
    ContainerSubmitButton,
    SubmitButton,
    CancelButton
} from './styles';
import StyledField from './StyledTextField';

type FormValues = {
    name: string;
    cpf: string;
    birth_date: string;
    creci: string;
    phone: string;
    email: string;
    password: string;
    confirm_password: string;
    agency_name: string;
    agency_cnpj: string;
    cep: string;
    address: string;
    neighborhood: string;
    city: string;
    state: string;
    complement: string;
    street: string;
    number: string;
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome completo é obrigatório'),
    cpf: Yup.string().required('CPF é obrigatório'),
    birth_date: Yup.string().required('Data de Nascimento é obrigatória'),
    phone: Yup.string().required('Telefone é obrigatório'),
    email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
    password: Yup.string().required('Senha é obrigatória'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'As senhas não coincidem')
        .required('Confirmação de senha é obrigatória'),
    agency_cnpj: Yup.string().required('O CNPJ é obrigatório')
});

export default function FormularioCorretorCV() {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarProps, setSnackbarProps] = useState({ type: '', message: '' });
    const history = useHistory();

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const mascaraCPF = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
            .substring(0, 14);
    };

    const mascaraTelefone = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
            .substring(0, 15);
    };

    function mascaraCNPJ(cnpj: string | number): string {
        const cnpjFormatado = cnpj
            .toString()
            .replace(/[^0-9]/g, '') 
            .slice(0, 14) 
        const cnpjArray = cnpjFormatado.split('');

        if (cnpjArray.length >= 2) {
            cnpjArray.splice(2, 0, '.');
            if (cnpjArray.length >= 6) {
                cnpjArray.splice(6, 0, '.');
                if (cnpjArray.length >= 11) {
                    cnpjArray.splice(10, 0, '/');
                    if (cnpjArray.length >= 15) {
                        cnpjArray.splice(15, 0, '-');
                    }
                }
            }
        }

        return cnpjArray.join('');
    }

    const mascaraCEP = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .substring(0, 9);
    };

    const mascaraData = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1/$2')
            .replace(/(\d{2})(\d)/, '$1/$2')
            .substring(0, 10);
    };

    const formatFormData = (data: FormValues) => ({
        name: data.name,
        email: data.email,
        password: data.password,
        document: data.cpf.replace(/\D/g, ''),
        birth_date: data.birth_date.split('/').reverse().join('-'),
        cnpj_agency: data.agency_cnpj,
        phone: data.phone.replace(/\D/g, ''),
        creci: data.creci,
        user_type: "COMMON",
        address: {
            rua: data.street,
            numero: data.number,
            complemento: data.complement,
            bairro: data.neighborhood,
            cidade: data.city,
            cep: data.cep ? data.cep.replace(/\D/g, '') : null,
            uf: data.state,
        },
    });

    const onSubmit = async (data: FormValues, actions: FormikHelpers<FormValues>) => {
        try {
            const formattedData = formatFormData(data);
            await UsuariosService.createCorretor(formattedData);
            history.push('/corretor/criado');
        } catch (error) {
            setSnackbarProps({ type: 'error', message: 'Erro ao criar corretor. Tente novamente!' });
            setSnackbarOpen(true);
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik
            initialValues={{
                name: '',
                cpf: '',
                birth_date: '',
                creci: '',
                phone: '',
                email: '',
                password: '',
                confirm_password: '',
                agency_name: '',
                agency_cnpj: '',
                cep: '',
                address: '',
                neighborhood: '',
                city: '',
                state: '',
                complement: '',
                street: '',
                number: ''
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ setFieldValue, handleSubmit, values }) => (
                <FormContainer>
                    <FormTitle>Formulário para cadastro do corretor</FormTitle>
                    <form onSubmit={handleSubmit}>
                        <Section>
                            <SectionTitle>Informações do corretor</SectionTitle>
                            <FieldsRow>
                                <InputWrapper>
                                    <StyledField
                                        name="name"
                                        label="Nome"
                                        setFieldValue={setFieldValue}
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <StyledField
                                        name="cpf"
                                        label="CPF"
                                        maskFunction={mascaraCPF}
                                        setFieldValue={setFieldValue}
                                    />
                                </InputWrapper>
                            </FieldsRow>

                            <FieldsRow>
                                <InputWrapper>
                                    <StyledField
                                        name="birth_date"
                                        label="Data de Nascimento"
                                        maskFunction={mascaraData}
                                        setFieldValue={setFieldValue}
                                    />
                                </InputWrapper>
                            </FieldsRow>

                            <FieldsRow>
                                <InputWrapper>
                                    <StyledField
                                        name="phone"
                                        label="Telefone"
                                        maskFunction={mascaraTelefone}
                                        setFieldValue={setFieldValue}
                                    />
                                </InputWrapper>
                            </FieldsRow>

                            <FieldsRow>
                                <InputWrapper>
                                    <StyledField
                                        name="email"
                                        label="E-mail"
                                        type="email"
                                        setFieldValue={setFieldValue}
                                    />
                                </InputWrapper>
                            </FieldsRow>

                            <FieldsRow>
                                <InputWrapper>
                                    <StyledField
                                        name="password"
                                        label="Senha"
                                        type={showPassword ? 'text' : 'password'}
                                        setFieldValue={setFieldValue}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={togglePasswordVisibility}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <StyledField
                                        name="confirm_password"
                                        label="Confirmar Senha"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        setFieldValue={setFieldValue}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={toggleConfirmPasswordVisibility}>
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </InputWrapper>
                            </FieldsRow>
                        </Section>

                        <SectionTitle>Endereço</SectionTitle>
                        <FieldsRow>
                            <InputWrapper>
                                <StyledField
                                    name="street"
                                    label="Rua"
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <StyledField
                                    name="city"
                                    label="Cidade"
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>
                        </FieldsRow>

                        <FieldsRow>
                            <InputWrapper>
                                <StyledField
                                    name="number"
                                    label="Número"
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <StyledField
                                    name="cep"
                                    label="CEP"
                                    maskFunction={mascaraCEP}
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>
                        </FieldsRow>

                        <FieldsRow>
                            <InputWrapper>
                                <StyledField
                                    name="neighborhood"
                                    label="Bairro"
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <StyledField
                                    name="state"
                                    label="Estado"
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>
                        </FieldsRow>

                        <FieldsRow>
                            <InputWrapper>
                                <StyledField
                                    name="complement"
                                    label="Complemento"
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>
                        </FieldsRow>

                        <SectionTitle>Informações da imobiliária</SectionTitle>
                        <FieldsRow>
                            <InputWrapper>
                                <StyledField
                                    name="agency_name"
                                    label="Nome da Imobiliária"
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <StyledField
                                    name="agency_cnpj"
                                    label="CNPJ da Imobiliária"
                                    maskFunction={mascaraCNPJ}
                                    setFieldValue={setFieldValue}
                                />
                            </InputWrapper>
                        </FieldsRow>

                        <ContainerSubmitButton>
                            <SubmitButton type="submit">Enviar</SubmitButton>
                            <CancelButton type="reset">Cancelar</CancelButton>
                        </ContainerSubmitButton>
                    </form>

                    <Snackbars
                        type={snackbarProps.type}
                        handleClose={handleSnackbarClose}
                        open={snackbarOpen}
                    >
                        {snackbarProps.message}
                    </Snackbars>
                </FormContainer>
            )}
        </Formik>
    );
}
