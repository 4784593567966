/* eslint-disable react/jsx-props-no-spreading */

import { Field, FieldProps } from 'formik';
import { TextField, TextFieldProps } from '@mui/material';
import styled from '@emotion/styled';

type CustomFieldProps = TextFieldProps & {
  name: string;
  label: string;
  maskFunction?: (value: string) => string;
  setFieldValue?: (field: string, value: string) => void;
};

const StyledTextField = styled(TextField)`
  margin-bottom: 16px;

  .MuiInputLabel-root {
    font-size: 1rem;
    color: #4a4a4a;
  }

  .MuiOutlinedInput-root {
    background-color: #f5f5f5;
    border-radius: 8px;

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #1976d2;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #1976d2;
    }
  }

  .MuiFormHelperText-root {
    color: #d32f2f;
    font-weight: 500;
  }
`;

const StyledField = ({
  name,
  label,
  type = 'text',
  fullWidth = true,
  variant = 'outlined',
  maskFunction,
  setFieldValue,
  ...props
}: CustomFieldProps) => (
  <Field name={name}>
    {({ field, form, meta }: FieldProps) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (maskFunction) {
          value = maskFunction(value);
        }
        if (setFieldValue) {
          setFieldValue(name, value);
        } else {
          form.setFieldValue(name, value);
        }
      };

      return (
        <StyledTextField
          {...props}
          label={label}
          type={type}
          fullWidth={fullWidth}
          variant={variant}
          value={field.value || ''}
          onChange={handleChange}
          onBlur={field.onBlur}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      );
    }}
  </Field>
);

export default StyledField;
