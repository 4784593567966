import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Content, TabelaContainer, OpcoesContainer } from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import Botao from '../../components/Botao/Botao';
import UsuariosService from '../../services/UserService';
import DeleteModal from '../../components/DeleteModal';
import Snackbars from '../../components/Snackbars';
import colors from '../../styles/cores';

const Index: React.FC = () => {
  const [userData, setUserData] = useState({});
  const [allUsers, setAllUsers] = useState<any>();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const history = useHistory();

  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const fetchData = useCallback(async () => {
    setIsDataLoading(true);
    console.log('entrei no corretores kk');
    await UsuariosService.listAllUsers().then(response => {
      setAllUsers(response.users);
    });
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Avatar',
      field: 'imageUrl',
      filtering: false,
      export: false,
      render: (rowData: any) => (
        <img
          src={
            rowData.avatar_url ||
            'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png'
          }
          style={{ width: 64, height: 64, borderRadius: '50%' }}
          alt="avatar"
        />
      ),
    },
    {
      title: '',
      field: 'new',
      render: (rowData: any) => (
        rowData.new ? (
          <span
            style={{
              backgroundColor: colors.purple_3,
              color: 'white',
              padding: '10px 15px',
              borderRadius: '4px',
            }}
          >
            Novo
          </span>
        ) : null
      ),
    },
    {
      title: 'Nome',
      field: 'name',
      export: false,
    },
    {
      title: 'Email',
      field: 'email',
      export: true,
    },
    {
      title: 'Tipo',
      field: 'user_type',
    },
    {
      title: 'Documento',
      field: 'document',
    },
    {
      title: 'Imobiliária',
      field: 'agency.nome',
    },
    {
      title: 'Ativo',
      field: 'is_inactive',
      render: (rowData: any) => {
        const isActive = rowData.is_inactive === null || !rowData.is_inactive;
        return (
          <span
            style={{
              backgroundColor: isActive ? colors.lime : colors.red,
              color: 'white',
              padding: '10px 15px',
              borderRadius: '4px',
            }}
          >
            {isActive ? 'Ativo' : 'Inativo'}
          </span>
        );
      },
    },    
  ];

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  function rowSelected(e: any, rowData: any) {
    const id = rowData.id;
    history.push({ pathname: '/painel/corretores/detalhes/', state: { id } });
  }
  function adicionarCorretor() {
    history.push(`/painel/corretores/adicionar`);
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  async function editarCorretores(rowData: any) {
    const userData = await UsuariosService.getUserData(rowData.id);
    const userDataPersonal = userData.user;
    const userDataAddress = userData.user.address || {};

    history.push({
      pathname: '/painel/corretores/editar',
      state: {
        id: userDataPersonal.id,
        name: userDataPersonal.name,
        document: userDataPersonal.document,
        email: userDataPersonal.email,
        birth_date: userDataPersonal.birth_date,
        phone: userDataPersonal.phone,
        agency_name: userDataPersonal.agency.nome,
        agency_id: userDataPersonal.agency_id,
        avatar_url: userDataPersonal.avatar_url,
        cep: userDataAddress.cep,
        rua: userDataAddress.rua || '',
        numero: userDataAddress.numero || '',
        bairro: userDataAddress.bairro || '',
        cidade: userDataAddress.cidade || '',
        uf: {
          title: userDataAddress.uf || '',
          value: userDataAddress.uf || ''
        },
        complemento: userDataAddress.complemento || '',
        enterprises: userDataPersonal.enterprises || [],
        creci: userDataPersonal.creci || '',
      },
    });
  }

  async function deletarRegistro(deleteData: any) {
    await UsuariosService.deleteUser(deleteData.id);
    setDeleteModalOpen(false);
    setSnackbarProps({
      message: 'Corretor deletado com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
    fetchData();
  }

  return (
    <>
      <Content>
        <OpcoesContainer>
          <Botao tipo="adicionar" tamanho="medio" onClick={adicionarCorretor}>
            Adicionar Corretor
          </Botao>
        </OpcoesContainer>

        <TabelaContainer>
          <Tabela
            title="Corretores"
            isLoading={isDataLoading}
            haveActions="editdelete"
            columns={columns}
            data={allUsers}
            onRowClick={(e: any, rowData: any): any => {
              setUserData(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={(e: any, rowData: any): any => {
              setDeleteModalOpen(true);
              setUserData(rowData);
            }}
            editarClick={(e: any, rowData: any): any => {
              setUserData(rowData);
              // const data = delete rowData.tableData;
              editarCorretores(rowData);
            }}
            pagination
            paginationPageSize={10}
          />
        </TabelaContainer>
        <DeleteModal
          isModalOpen={deleteModalOpen}
          onCloseModal={() => setDeleteModalOpen(false)}
          onDeleteClick={() => deletarRegistro(userData)}
        />
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
      </Content>
    </>
  );
};

export default Index;
