import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Botao from '../../../components/Botao/Botao';

import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  Usuario,
  BotoesContainer,
  InputsContainer,
  InputsContent,
  ContainerClipBoard,
  InputFieldClipBoard,
  CopyButton,
} from './styles';

import InputField from '../../../components/Inputs/InputField';
import { mascaraCPF, mascaraTelefone } from '../../../utils/mascaras';
import ImobiliariasService from '../../../services/ImobiliariasService';
import UserService from '../../../services/UserService';
import Snackbars from '../../../components/Snackbars';
import { validaCPF } from '../../../utils/validacoes';
import { ufOptions } from '../../../utils/selects';


const NovoCorretor = () => {
  const history = useHistory()

  // eslint-disable-next-line prefer-const
  let [endereco, setEndereco] = useState({
    bairro: '',
    cep: '',
    complemento: '',
    localidade: '',
    logradouro: '',
    uf: '',
  });

  const [bairroUser, setBairro] = useState('');
  const [complementoUser, setComplemento] = useState('');
  const [cidadeUser, setCidade] = useState('');
  const [logradouroUser, setLogradouro] = useState('');
  const [UFUser, setUF] = useState({ title: '', value: '' });
  const [nomeUser, setNomeUser] = useState('');
  const [documento, setDocumento] = useState('');
  const [invalid, setInvalid] = useState<any>(false)
  const [emailValid, setEmailValid] = useState<any>(false)
  const [creci, setCreci] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cepUser, setCEP] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [password, setPassword] = useState('');
  const [imobiliariaUser, setImobiliariaUser] = useState<any>([]);
  const [imobiliariaSelected, setImobiliariaSelected] = useState('');
  const [birth_date, setBirthDate] = useState<any>('');
  const [numeroEndereco, setNumeroEndereco] = useState('');
  const [allAgencies, setAllAgencies] = useState<any>();
  const [allEnterprisesNames, setAllEnterprisesName] = useState<string[]>();
  const [allEnterprises, setAllEnterprises] = useState<any[]>([{}]);
  const [enterprisesNames, setEnterprisesNames] = useState<string[]>()
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [valorIndice, setValorIndice] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const urlCorretor = "www.vendas.bairru.com.br/criar-corretor";

  const handleCopy = () => {
    setCopied(true);
    setSnackbarProps({
      message: 'Copiado!',
      type: 'success',
    });
    setSnackbarOpen(true);
  };

  const fetchEnterprises = useCallback(async (agency_id) => {
    await ImobiliariasService.findImobiliaria(agency_id).then((response) => {
      setAllEnterprises(response.agency.enterprises)
      setAllEnterprisesName(response.agency.enterprises.map((enterpriseName: any) => {
        return enterpriseName.name
      }))
      setEnterprisesNames(response.agency.enterprises.map((enterpriseName: any) => {
        return enterpriseName.name
      }))
    })
  }, [])

  useEffect(() => {
    if (allAgencies !== undefined) {
      const valorIndice = allAgencies.findIndex((checkAllAgencies: { nome: string; }) => checkAllAgencies.nome === imobiliariaSelected)
      setValorIndice(valorIndice)

      const imob = allAgencies[valorIndice]
      fetchEnterprises(imob?.id)
    }

  }, [imobiliariaSelected])

  const fetchAgencies = useCallback(async () => {
    const { agencies } = await ImobiliariasService.listAllImobiliarias()
    setAllAgencies(agencies)
  }, [])

  useEffect(() => {
    fetchAgencies()
  }, [])

  const baseURL = 'https://viacep.com.br/ws/';

  const voltarTela = async () => {
    history.goBack()
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const getEndereco = async (cep: string) => {
    try {
      await axios.get(`${baseURL}${cep}/json/`).then(res => {
        const teste = res.data;
        setEndereco(teste);
      });
    } catch (error) {
      console.log('erro');
    }
  };

  useEffect(() => {
    setLogradouro(endereco.logradouro);
    setBairro(endereco.bairro);
    setCidade(endereco.localidade);
    setComplemento(endereco.complemento);
    setUF({ title: endereco.uf, value: endereco.uf });
  }, [endereco]);

  async function autoComplete(e: any) {
    setCEP(e.target.value.toString());
    if (e.target.value.length >= 8) {
      setLogradouro('');
      setBairro('');
      setCidade('');
      setComplemento('');
      setUF({ title: '', value: '' });
      await getEndereco(e.target.value.toString());
    }
  }

  function setUFValue(e: any) {
    setUF({
      title: e.target.outerText,
      value: e.target.outerText.toLowerCase(),
    });
  }

  const [errorValidation, setErrorValidation] = useState({
    nome: { error: false, message: '' },
    documento: { error: false, message: '' },
    email: { error: false, message: '' },
    imobiliaria: { error: false, message: '' },
    senha: { error: false, message: '' },
    nascimento: { error: false, message: '' },
    cep: { error: false, message: '' },
    rua: { error: false, message: '' },
    numero: { error: false, message: '' },
    bairro: { error: false, message: '' },
    cidade: { error: false, message: '' }
  });

  let errors: Array<boolean> = [];
  errors = [false, false, false, false, false, false, false, false];
  let errorMessage: Array<string> = [];
  errorMessage = ['', '', '', '', '', '', '', ''];

  function handleErrorChange(e: any, inputError: any) {

    if (e.target.innerText !== '') {
      setErrorValidation({
        ...errorValidation,
        [inputError]: { error: false, message: '' },
      });
    }
  }
  let error = false;

  const handleChange = async (event: any) => {
    setEnterprisesNames(event.target.value);
  }

  const onSubmit = async (dataSubmit: any) => {
    console.log(dataSubmit)


    if (dataSubmit.senha === '') {
      errors[4] = true;
      errorMessage[4] = 'Campo obrigatório'
    }

    if (dataSubmit.nome.length < 2) {
      error = true;
      errors[0] = true;
      errorMessage[0] = 'Campo obrigatório!';
    }
    if (dataSubmit.nome.length >= 2) {
      errors[0] = false;
    }

    if (dataSubmit.documento === '') {
      error = true;
      errors[1] = true;
      errorMessage[1] = 'Campo obrigatório!';
    }
    if (dataSubmit.documento !== '') {
      errors[1] = false;
    }

    if (invalid === true) {
      errors[1] = true;
      errorMessage[1] = 'CPF Inválido!';
    }


    if (dataSubmit.imobiliaria === '') {
      error = true;
      errors[3] = true;
      errorMessage[3] = 'Campo obrigatório!';
    }

    if (emailValid === true) {
      error = true;
      errors[2] = true;
      errorMessage[2] = 'Email Inválido!';
    }

    if (dataSubmit.email === '') {
      error = true;
      errors[2] = true;
      errorMessage[2] = 'Campo obrigatório!';
    }

    if (dataSubmit.email !== '') {
      errors[2] = false;
      if (dataSubmit.email.length < 5) {
        error = true;
        errors[2] = true;
        errorMessage[2] = 'Email inválido!';
      } else if (dataSubmit.email.length > 5) {
        errors[2] = false;
        errorMessage[2] = '';
      }
    }

    if (dataSubmit.nascimento === '') {
      error = true;
      errors[5] = true;
      errorMessage[5] = 'Campo obrigatório'
    }

    setErrorValidation({
      ...errorValidation,
      nome: { error: errors[0], message: errors[0] ? errorMessage[0] : '' },
      documento: {
        error: errors[1],
        message: errors[1] ? errorMessage[1] : '',
      },
      email: { error: errors[2], message: errors[2] ? errorMessage[2] : '' },
      senha: { error: errors[4], message: errors[4] ? errorMessage[4] : '' },
      imobiliaria: {
        error: errors[3],
        message: errors[3] ? errorMessage[3] : '',
      },
      nascimento: { error: errors[5], message: errors[5] ? errorMessage[5] : '' },
    });

    if (error === false && imobiliariaUser !== undefined) {
      try {
        const response = await UserService.createUser({
          email: emailUser,
          document: documento,
          name: nomeUser,
          birth_date,
          phone: telefone,
          agency_id: allAgencies[valorIndice].id,
          creci: creci,
          password,
        })

        await UserService.updateUserAddress({
          user_id: response.user.id,
          uf: UFUser.value,
          cidade: cidadeUser,
          cep: cepUser,
          rua: logradouroUser,
          numero: numeroEndereco,
          complemento: complementoUser,
          bairro: bairroUser
        })

        if (enterprisesNames) {
          const enterprises_id = allEnterprises.filter((enterprise: any) =>
            enterprisesNames.indexOf(enterprise.name) > -1
          ).map((enterprise: any) => enterprise.id)

          await UserService.updateUserEnterprises({
            user_id: response.user.id,
            enterprises_id
          })
        }
        history.goBack();
      }
      catch (e: any) {
        setSnackbarProps({
          message: e.message,
          type: 'error',
        });
        setSnackbarOpen(true);
      }
    }

  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const validCPF = (cpf: any) => {
    let cpfFormated = cpf.target.value
    let validateResult = validaCPF(cpfFormated)
    setDocumento(mascaraCPF(cpfFormated))
    if (cpfFormated === '') {
      setInvalid(false)
      errorValidation.documento.error = true
    }
    else if (validateResult === false) {
      setInvalid(true)
      errorValidation.documento.error = true
    }
    else {
      setInvalid(false)
      errorValidation.documento.error = false;
      errorValidation.documento.message = '';
    }
  }

  const emailValidation = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailFormated = email.target.value
    let validation = emailRegex.test(emailFormated)
    if (emailFormated === '') {
      errorValidation.email.error = true
      setEmailValid(false)
    }
    else if (validation === false) {
      setEmailValid(true)
      errorValidation.email.error = true
    }
    else {
      setEmailValid(false)
      errorValidation.email.error = false;
      errorValidation.email.message = '';
      setEmailUser(emailFormated);
    }
  }

  const telefoneValidation = (cellphone: any) => {
    let tempTelefone = cellphone.target.value;
    setTelefone(tempTelefone)
  }

  const passwordValidation = (senha: any) => {
    let tempSenha = senha.target.value;
    setPassword(tempSenha);
    if (tempSenha.length > 2) {
      errorValidation.senha.error = false;
      errorValidation.senha.message = '';
    }
    else {
      errorValidation.senha.error = true;
      errorValidation.senha.message = 'Senha curta demais!';
    }
  }

  const nameValidation = (name: any) => {
    let tempName = name.target.value;
    setNomeUser(tempName)
    if (tempName.length > 2) {
      errorValidation.nome.error = false;
      errorValidation.nome.message = '';
    }
    else {
      errorValidation.nome.error = true;
      errorValidation.nome.message = '';
    }
  }

  const birthDateValidation = (birthDate: any) => {
    let tempBirth = birthDate.target.value;
    setBirthDate(tempBirth)
    if (tempBirth !== '') {
      errorValidation.nascimento.error = false;
      errorValidation.nascimento.message = '';
    }
    else {
      errorValidation.nascimento.error = true;
      errorValidation.nascimento.message = 'DATA INVÁLIDA';
    }
  }

  const creciValidation = (creci: any) => {
    let tempCreci = creci.target.value;
    setCreci(tempCreci)
  }

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <Usuario>
              <img
                src={'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png'}
                style={{ width: 128, height: 128, borderRadius: '100%' }}
                alt="avatar"
              />
              <h1>Novo Corretor</h1>
            </Usuario>
            <div style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              alignItems: 'center'
            }}>
              <ContainerClipBoard>
                <InputFieldClipBoard type="url" value={urlCorretor} />
                <CopyToClipboard text={urlCorretor} onCopy={handleCopy}>
                  <CopyButton copied={copied}>
                    <ContentCopyIcon fontSize="small" />
                  </CopyButton>
                </CopyToClipboard>
              </ContainerClipBoard>
              <Botao
                tipo="editar"
                tamanho="grande"
                className="btn"
                onClick={voltarTela}
              >
                Voltar
              </Botao>
            </div>
          </DetalhesHeader>
          <h1>Dados:</h1>
          <DetalhesContent>
            <InputsContent>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Nome"
                  value={nomeUser}
                  onChange={(e: any) => {
                    nameValidation(e);
                    handleErrorChange(e, 'nome');
                  }}
                  error={errorValidation.nome.error}
                  helperText={errorValidation.nome.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CPF"
                  value={documento}
                  onChange={(e: any) => {
                    validCPF(e)
                  }}
                  error={errorValidation.documento.error}
                  helperText={errorValidation.documento.message}
                />
                {
                  invalid === false ? <></>
                    : <>
                      <text style={{ color: 'red', fontSize: 12 }}>* CPF INVÁLIDO</text>
                    </>
                }
              </InputsContainer>
              <InputsContainer>

                {
                  allAgencies ? <>
                    <Autocomplete
                      id="combo-box-demo"
                      options={allAgencies.map((e: { nome: any; }, index: any) => {
                        let transformedAllAgenciesObjToSelectedInputObj = { title: e.nome, value: e.nome }
                        return transformedAllAgenciesObjToSelectedInputObj
                      })}
                      getOptionLabel={(option: any) => typeof option === 'string' && option !== '' ? option : option.title}

                      style={{ width: 200 }}
                      value={imobiliariaSelected}
                      disableClearable
                      onChange={(e: any) => {
                        setImobiliariaSelected(e.target.innerText);
                        handleErrorChange(e, 'imobiliaria');
                      }}
                      renderInput={params => (
                        <TextField
                          label="Imobiliária"
                          variant="outlined"
                          error={errorValidation.imobiliaria.error}
                          helperText={errorValidation.imobiliaria.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          size="small"
                        />
                      )}
                    />

                  </>
                    :
                    <>

                    </>

                }
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Telefone"
                  value={mascaraTelefone(telefone)}
                  onChange={(e: any) => {
                    telefoneValidation(e)
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Email"
                  onChange={(e: any) => {
                    emailValidation(e)
                    handleErrorChange(e, 2);
                  }}
                  error={errorValidation.email.error}
                  helperText={errorValidation.email.message}
                />
                {
                  emailValid === false ? <></>
                    : <>
                      <text style={{ color: 'red', fontSize: 12 }}>* EMAIL INVÁLIDO</text>
                    </>
                }
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Senha"
                  value={password}
                  onChange={(e: any) => {
                    passwordValidation(e)
                    handleErrorChange(e, 'senha');
                  }}
                  error={errorValidation.senha.error}
                  helperText={errorValidation.senha.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  type="string"
                  label="CRECI"
                  value={creci}
                  onChange={(e: any) => {
                    creciValidation(e)
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <TextField
                  id="date"
                  label="Data de nascimento"
                  type="date"
                  defaultValue=""
                  value={birth_date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e: any) => {
                    birthDateValidation(e)
                    handleErrorChange(e, 'date');
                  }}
                  data-date-format="DD MMMM YYYY"
                  error={errorValidation.nascimento.error}
                  helperText={errorValidation.nascimento.message}
                />
              </InputsContainer>
            </InputsContent>

            <InputsContainer>
              <FormControl
                style={{
                  width: 200,
                  marginLeft: 10,
                  // height: 40,
                }}>
                <InputLabel
                  id="demo-mutiple-checkbox-label"
                  style={{ marginTop: -10, marginLeft: 14 }}
                >
                  Empreendimentos
                </InputLabel>
                {(!allEnterprisesNames || !enterprisesNames) ? '' :
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    variant="outlined"
                    style={{ width: 200, height: 40 }}
                    value={enterprisesNames}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        style={{ height: 40 }}
                        label="Empreendimentos"
                      />
                    }
                    renderValue={(selected: any) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {allEnterprisesNames.map((enterprise: any) => (
                      <MenuItem key={enterprise} value={enterprise}>
                        <Checkbox checked={enterprisesNames.indexOf(enterprise) > -1} />
                        <ListItemText primary={enterprise} />
                      </MenuItem>
                    ))}
                  </Select>}
              </FormControl>
            </InputsContainer>

            <div className="endereco">
              <h2>Endereço: </h2>
            </div>

            <InputsContent>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CEP"
                  width={100}
                  onChange={(e: any) => {
                    autoComplete(e)
                    handleErrorChange(e, 'cep');
                  }}
                  value={cepUser}
                  error={errorValidation.cep.error}
                  helperText={errorValidation.cep.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Rua"
                  value={logradouroUser}
                  onChange={(e: any) => {
                    setLogradouro(e.target.value);
                    handleErrorChange(e, 'rua');
                  }}
                  error={errorValidation.rua.error}
                  helperText={errorValidation.rua.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Número"
                  type="numeric"
                  onChange={(e: any) => {
                    setNumeroEndereco(e.target.value);
                    handleErrorChange(e, 'numero');
                  }}
                  error={errorValidation.numero.error}
                  helperText={errorValidation.numero.message}
                  width={120}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Complemento"
                  value={complementoUser}
                  onChange={(e: any) => setComplemento(e.target.value)}
                  width={200}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Bairro"
                  value={bairroUser}
                  onChange={(e: any) => {
                    setBairro(e.target.value);
                    handleErrorChange(e, 'bairro')
                  }}
                  error={errorValidation.bairro.error}
                  helperText={errorValidation.bairro.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Cidade"
                  value={cidadeUser}
                  onChange={(e: any) => {
                    setCidade(e.target.value);
                    handleErrorChange(e, 'cidade');
                  }}
                  error={errorValidation.cidade.error}
                  helperText={errorValidation.cidade.message}
                />
              </InputsContainer>
              <InputsContainer>
                <Autocomplete
                  id="combo-box-demo"
                  options={ufOptions}
                  getOptionLabel={option => option.title}
                  style={{ width: 100 }}
                  value={UFUser}
                  onChange={setUFValue}
                  disableClearable
                  renderInput={params => (
                    <TextField
                      label="UF"
                      variant="outlined"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      size="small"
                      error={false}
                      helperText=""
                    />
                  )}
                />
              </InputsContainer>
            </InputsContent>

            <BotoesContainer>
              <Botao
                tipo="salvar"
                tamanho="grande"
                className="btn-salvar"
                onClick={() => {
                  onSubmit({
                    nome: nomeUser,
                    documento: documento,
                    imobiliaria: imobiliariaSelected,
                    telefone: telefone,
                    email: emailUser,
                    nascimento: birth_date,
                    senha: password,
                    creci: creci,
                    endereco: {
                      cep: cepUser,
                      logradouro: logradouroUser,
                      numero: numeroEndereco,
                      complemento: complementoUser,
                      bairro: bairroUser,
                      cidade: cidadeUser,
                      // uf: UFUser.value,
                    },
                  });
                }}
              >
                Salvar Corretor
              </Botao>
            </BotoesContainer>
          </DetalhesContent>
        </DetalhesContainer>
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
      </Content>
    </>
  );
};

export default NovoCorretor;
