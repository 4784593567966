import axios from 'axios';

// const baseURL = process.env.API_URL;
// const baseURL = process.env.LOCALHOST_URL;
const baseURL = 'https://apivendas.bairru.com.br/';

// const baseURL = 'http://xxxx:3334';

const api = axios.create({ baseURL });

export default api;
